import '@extensions/string';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { TransferClient } from '@models/transfer-client';
import { RootState } from '@redux/reducers';
import { addNotification } from '../notification';
import { ThunkCallback } from '../model/thunk-callback';

export const fetchTransferClients = createAsyncThunk<PaginatedResponseApi<TransferClient.Response>, TransferClient.Params | void>(
    'transfer-clients/fetch-clients',
    async (request, thunkAPI) => {
        try {
            const response = await axios.get<PaginatedResponseApi<TransferClient.Response>>(
                '/clientetransfer/byusuario',
                {
                    params: request
                },
            );

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListingClients'),
            );
        }
    },
);

export const addTransferClients = createAsyncThunk<TransferClient.Response, ThunkCallback<TransferClient.Request>>(
    'transfer-clients/add-clients',
    async (request, thunkAPI) => {
        try {
            const response = await axios.post<TransferClient.Response>(
                '/clientetransfer',
                request.data,
            );

            const { data, status } = response;
            if (status === 200 && data) {
    
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.registeredUpdated'),
                        title: translate('general.success'),
                        notificationKey: request.notificationKey,
                        callback: request.onSuccess,
                    }),
                );
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);


export const updateTransferClients = createAsyncThunk<TransferClient.Response, ThunkCallback<{id: number} & TransferClient.Request>>(
    'transfer-clients/update-clients',
    async (request, thunkAPI) => {
        try {
            const { id, ...reqData }= request.data
            const response = await axios.put<TransferClient.Response>(
                '/clientetransfer/' + id,
                reqData,
            );

            const { data, status } = response;
            if (status === 200 && data) {
    
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.registeredUpdated'),
                        title: translate('general.success'),
                        notificationKey: request.notificationKey,
                        callback: request.onSuccess,
                    }),
                );
                return response.data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);

export const deleteTransferClient = createAsyncThunk<number, number>(
    'transfer-clients/delete-clients',
    async (id, thunkAPI) => {
        try {
            const response = await axios.delete('/clientetransfer/' + id);
            if (response.status === 204) {
                thunkAPI.dispatch(deleteFromState(id));
                return Promise.resolve(id);
            }
            return -0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

type TPromoterOnLotRequest = {
    idPromotorDe: number
    idPromotorPara: number
}

export const changePromoterOnLot = createAsyncThunk<void, ThunkCallback<TPromoterOnLotRequest>>(
    'transfer-clients/change-promoter',
    async (request, thunkAPI) => {
        try {
            const response = await axios.put('/clientetransfer/updateall', request.data);

            const { data, status } = response;
            if (status === 200) {
    
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.success'),
                        title: translate('general.success'),
                        notificationKey: request.notificationKey,
                        callback: request.onSuccess,
                    }),
                );
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.debug('Error', e);

            let message = ''
            if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
                message = e.response.data[0].msgUsuario;
            }else{
                message = translate('general.error');
            }
            
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: message,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
            return thunkAPI.rejectWithValue(e);
        }
    },
);


interface ITransferClientState {
    available: PaginatedResponseApi<TransferClient.Response>;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: TransferClient.Params;
}

const initialState: ITransferClientState = {
    available: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: undefined,
};

const transferClientSlice = createSlice({
    name: 'transfer-client-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };

            return state;
        },
        deleteFromState: (state, action: PayloadAction<number>) => {
            state.available.content = state.available.content.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
        filterClients: (state, action: PayloadAction<TransferClient.Params>) => {
            state.filter = action.payload;
            return state;
        },
    },
    extraReducers: {
        [fetchTransferClients.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferClients.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchTransferClients.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferClient.Response>>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.available = payload;
            return state;
        },
    },
});


export default transferClientSlice.reducer;


export const transferClientsSelector = (state: RootState): ITransferClientState => state.transferClients;

export const { clearState, deleteFromState, filterClients } = transferClientSlice.actions;