import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { ModalContext } from 'src/contexts/modal-context';
import { editableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { translate } from '@components/i18n';
import { divisionValues } from '@redux/slices/divisions';
import { orderTypeSelector } from '@redux/slices/order';
import { TransferItem } from '@models/transfer-item';
import { addTransferItem, deleteTransferItem, fetchTransferItems, updateTransferItem } from '@redux/slices/transfer-item';
import { transferClientsSelector } from '@redux/slices/transfer-clients';
import { campaignSelector } from '@redux/slices/campaign';

interface EditModalProps {
    data?: TransferItem.Response;
}

export const EditModal: React.FC<EditModalProps> = memo(({ data }) => {
    const divisions = useSelector(divisionValues);
    const { allAvailablePlatforms } = useSelector(orderTypeSelector);
    const { available } = useSelector(transferClientsSelector);
    const { allItems } = useSelector(campaignSelector);

    const dispatch = useDispatch();
    const [form] = Form.useForm<TransferItem.Response>();
    const [editState, setEditState] = useState<TransferItem.Response>(data);
    const modalContext = useContext(ModalContext);

    const { pathname } = useLocation()
    const isRegister = pathname.includes("/register")

    const onOk = (values): void => {
        if (!isRegister) return;
        const item = allItems.find(item => item.numero === values.numero)
        if (!item) {
            message.error(translate('general.itemRequired'))
            return
        }

        if (item) {
            values.descricao = item.descricao
            values.divisao = item.divisao
            values.multiplo = item.multiplo || 1
        }

        values.preco = parseFloat(values.preco)

        if (!data) addNew(values);
        else handleUpdate(values);
    };

    const handleUpdate = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        const req = Object.assign(new TransferItem.RequestUpdate(), { id: data.id, ...values })

        dispatch(
            updateTransferItem({
                data: req,
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                    dispatch(fetchTransferItems())
                },
            }),
        );
    };

    const addNew = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        const data = Object.assign(new TransferItem.Request(), values)

        dispatch(
            addTransferItem({
                data,
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                    dispatch(fetchTransferItems())
                },
            }),
        );
    };

    useEffect(() => {
        if (data) {
            setEditState(data);
            form.setFieldsValue(data);
        } else {
            form.resetFields();
        }
    }, [data]);

    const onChangeValues = (
        changedValues: Partial<TransferItem.Response>,
        allValues: TransferItem.Response,
    ): void => {
        const values = {}
        Object.assign(values, allValues);
        Object.assign(values, changedValues);
        setEditState(values as TransferItem.Response);
    };

    const handleDelete = (): void => {
        dispatch(deleteTransferItem(data.id));
        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editState }}
                    onFinish={onOk}
                    form={form}
                    fields={editableFields({
                        canEdit: isRegister,
                        newData: !data,
                        optionsAllAvailablePlatforms: allAvailablePlatforms.map((c) => ({
                            id: c.id,
                            name: `${c.id} - ${c.description}`,
                        })),
                        optionsClients: available?.content?.map((c) => ({
                            id: c.id,
                            name: `${c.id} - ${c.nome}`,
                        })) || [],
                        optionsAllItems: allItems.map(c => ({
                            id: c.numero,
                            name: `${c.numero} - ${c.divisao} - ${c.descricao}`,
                        })) || [],
                    })}
                    withDelete={!!data && isRegister}
                    onDelete={handleDelete}
                    widthOk={isRegister}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
});
