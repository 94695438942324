import React, { memo, useContext } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { promoterFields } from '../constants';
import { FormBuilder } from '@components/form';
import { userSelector } from '@redux/slices/users';
import { translate } from '@components/i18n';
import { TransferClient } from '@models/transfer-client';
import { changePromoterOnLot, fetchTransferClients } from '@redux/slices/transfer-clients';
import { authSelector } from '@redux/slices/auth';

export const ChangePromter: React.FC = memo(() => {
    const auth = useSelector(authSelector)
    const usersState = useSelector(userSelector)

    const dispatch = useDispatch();
    const [form] = Form.useForm<TransferClient.Response>();
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updating'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            changePromoterOnLot({
                data: {
                    idPromotorDe: values.idPromotorDe,
                    idPromotorPara: values.idPromotorPara,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                    dispatch(fetchTransferClients())
                },
            }),
        );
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    form={form}
                    fields={promoterFields({
                        promoters: usersState?.availableUsers?.map(c => {
                            if (c.id === auth?.currentUser?.id && auth.isAdmin) {
                                return ({
                                    id: c.id,
                                    name: `${c.jdeCode} - (Eu) ${c.name}`,
                                })
                            }

                            return ({
                                id: c.id,
                                name: `${c.jdeCode} - ${c.name}`,
                            })
                        }) || [],
                    })}
                    onFinish={onOk}
                />
            </Col>
        </Row>
    );
});
