import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { fetchAvailablePlatforms, fetchAvailableTypes, } from '@redux/slices/order';
import { Title } from '@styles/components/text';
import { columns, filterTypes } from './constants';
import { SorterResult } from 'antd/lib/table/interface';
import { CustomerResponse } from '@models/customer';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { SearchOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { Distributor } from '@models/distributor';
import { distributorSelector, fetchDistributors } from '@redux/slices/distributor';
import { updateTransferDistributor } from '@redux/slices/transfer-order/order';

export const SelectDistributor: React.FC = () => {
    const selectDistributorState = useSelector(distributorSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const loadData = (
        pagination: any,
        _,
        sorter: SorterResult<unknown>,
    ): void => {

        const params: Distributor.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(fetchDistributors(params));
    };

    const onSelect = (rowKey: Distributor.Response): void => {
        dispatch(fetchAvailableTypes());
        dispatch(fetchAvailablePlatforms({}))

        dispatch(updateTransferDistributor({ distributor: rowKey }));
        history.push('/create-transfer-order/select-type');
    };

    const search = (): void => {
        dispatch(
            fetchDistributors({
                page: 0,
                size: 10,
                filterType: form.getFieldValue("filterType") || "TODOS",
                filterString: form.getFieldValue("filterString"),
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchDistributors({
            page: 0,
            size: 20,
        }))
    }, []);

    return (
        <Page
            title={translate('pages.searchDistributor')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.searchDistributor')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    columns={columns}
                                    form={form}
                                    pointer
                                    rowKey={(record: CustomerResponse) =>
                                        `${record.id}-${record.company}`
                                    }
                                    dataSource={selectDistributorState?.available?.content || []}
                                    onRowClick={onSelect}
                                    pagination={{
                                        current:
                                            selectDistributorState?.available?.number ||
                                            1,
                                        pageSize: selectDistributorState?.available?.size || 10,
                                        total: selectDistributorState?.available?.totalElements
                                    }}
                                    isLoading={selectDistributorState?.isFetching}
                                    onChange={loadData}
                                    initialValues={{
                                        filterType: 'TODOS'
                                    }}
                                    filters={[
                                        <FormItem
                                            label={translate('forms.labels.searchFor')}
                                            name="filterType"
                                        >
                                            <Select
                                                placeholder={translate('general.all')}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                                onChange={e => form.setFieldsValue({ filterType: e })}
                                            >
                                                {filterTypes.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.search',
                                                )}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    form.setFieldsValue({ filterString: e.target.value })
                                                }
                                            />
                                        </Form.Item>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
