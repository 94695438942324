export class TransferClientResponse {
    bairro: string;
    cidade: string;
    cnpj: string;
    codigoDistribuidor: number;
    idPromotor: number;
    email: string;
    endereco1: string;
    endereco2: string;
    nome: string;
    zona: string;
    id: number;
}