import React, { memo, useContext } from 'react';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SorterResult, FilterValue } from 'antd/lib/table/interface';
import { ModalContext } from 'src/contexts/modal-context';
import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { fetchTransferItems, transferItemsSelector } from '@redux/slices/transfer-item';
import { TransferItem } from '@models/transfer-item';
import { columns } from '../constants';
import { EditModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const dispatch = useDispatch();
    const itemData = useSelector(transferItemsSelector);
    const modalContext = useContext(ModalContext);

    const edit = (
        data: TransferItem.Response,
        _: number,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        modalContext.setIsOpen(
            true,
            <EditModal data={data} key={`editing-item-${data.numero}`} />,
            {
                title: `${translate('general.editItem')} - ${data.numero}`,
                width: '40%',
            },
        );
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferItem.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order}`
            })
        }

        dispatch(fetchTransferItems(params));
    };

    return (
        <Col>
            <Table
                columns={columns}
                rowKey={(record: TransferItem.Response) => `${record.id}`}
                dataSource={itemData?.available?.content || []}
                pagination={{
                    current: (itemData?.available?.number || 0) + 1,
                    pageSize: itemData?.available?.size || 10,
                    total: itemData?.available?.totalElements || 0
                }}
                pointer
                onRowClick={edit}
                scroll={{ y: 'calc(100vh - 380px)' }}
                onChange={loadData}
                isLoading={itemData.isFetching}
            />
        </Col>
    );
});
