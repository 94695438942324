import React, { useContext } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { Col, Row, Tabs, Table as AntTable, Tag } from 'antd';
import { approvalSelector, orderItemsSelector } from '@redux/slices/order';
import { bonificationItemsColumns, itemsColumns } from '../constants';
import { Table } from '@components/table';
import { useHistory } from 'react-router-dom';
import { ApprovalContext } from '../context/context';
import { Title } from '@styles/components/text';
import { OrderInfo } from './order-info';
import { Comments } from './comments';
import { OrderActions } from './order-actions';
import { HeaderMenu, HeaderMenuTitle } from '@components/header/styled';
import { RightOutlined } from '@ant-design/icons';
import { ItemResponse } from '@models/order/items/response';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderDetailsTableFooter } from './order-details-table-footer';
import { Semaphore } from '@components/semaphore';
import { colors } from '@utils/constants';
import { translate } from '@components/i18n';
import { List } from '@components/list';
import { SelectedMoeda } from '@models/order/moedas/response';
import { FromTransfer } from './from-transfer';

const { TabPane } = Tabs;

interface ParamProps {
    id?: string;
}

export const OrderDetails: React.FC = () => {
    const context = useContext(ApprovalContext);
    const { availableApprovals } = useSelector(approvalSelector);
    const { moedas, moedaSimbolos } = useSelector(orderItemsSelector);
    const history = useHistory();
    const { id } = useParams<ParamProps>();

    const getMoeda = (): SelectedMoeda => {
        const moeda = moedas.find(item => item.codigo === context?.order?.codigoMoeda)
        const simbolo = moedaSimbolos.find(item => item.codigoMoeda === context?.order?.codigoMoeda)

        return {
            ...moeda,
            simbolo: simbolo?.simbolo
        }
    }

    return (
        <>
            <FromTransfer />
            <ShadowContainer style={{ marginTop: 30 }}>
                <Row
                    style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
                    align={'middle'}
                    gutter={10}
                    justify="space-between"
                >
                    <Col span={24} lg={{ span: 10 }}>
                        <Row align={'middle'}>
                            <Title
                                level={3}
                                blue
                                style={{ marginBottom: 0, marginRight: 5 }}
                            >
                                {`${translate('titlePages.orders')} >`}
                            </Title>
                            <Title
                                level={5}
                                blue
                                style={{ marginBottom: 0, marginTop: 0 }}
                            >
                                {translate('general.query')}
                            </Title>
                        </Row>
                    </Col>
                    <Col>
                        {!context.readOnly && (
                            <Row
                                align="middle"
                                gutter={10}
                                style={{
                                    opacity: availableApprovals.length > 1 ? 1 : 0,
                                }}
                            >
                                <Col>
                                    <HeaderMenuTitle>
                                        {translate('general.nextOrder')}
                                    </HeaderMenuTitle>
                                </Col>
                                <Col>
                                    <HeaderMenu
                                        onClick={() => {
                                            const next = availableApprovals.filter(
                                                (i) => `${i.draftRef}` !== `${id}`,
                                            );

                                            history.push(
                                                '/approval-order/' +
                                                next[0].draftRef,
                                            );
                                        }}
                                    >
                                        <RightOutlined />
                                    </HeaderMenu>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24} lg={{ span: 12 }}>
                        <OrderInfo />
                    </Col>
                    <Col span={24} lg={{ span: 8 }}>
                        <Comments comments={context.comments ?? []} />
                    </Col>
                    <Col span={24} lg={{ span: 4 }}>
                        <OrderActions />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={0} lg={{ span: 23 }}>
                        <Tabs
                            defaultActiveKey="bonification"
                            style={{ marginTop: 20 }}
                            key={context.bonificationOrder?.key}
                        >
                            {!!context.bonificationOrder && (
                                <TabPane
                                    tab={
                                        <Row gutter={10}>
                                            <Col>
                                                {translate('columns.bonification')}{' '}
                                                - {context.bonificationOrder.key}{' '}
                                            </Col>
                                            (
                                            <Semaphore
                                                order={context.bonificationOrder}
                                                moeda={getMoeda()}
                                            />
                                            )
                                        </Row>
                                    }
                                    key={'bonification'}
                                >
                                    <Table
                                        columns={bonificationItemsColumns}
                                        rowKey={(record: ItemResponse) =>
                                            `${record.id}-${context.bonificationOrder.key}`
                                        }
                                        pointer
                                        dataSource={
                                            context.bonificationOrder?.tempItems ??
                                            []
                                        }
                                        pagination={undefined}
                                        isLoading={context.loading}
                                        summary={
                                            context.bonificationOrder &&
                                                context.bonificationOrder?.campanhaIds
                                                    .length > 0
                                                ? () => (
                                                    <AntTable.Summary
                                                        fixed={true}
                                                    >
                                                        <AntTable.Summary.Row>
                                                            <AntTable.Summary.Cell
                                                                index={2}
                                                                colSpan={16}
                                                            >
                                                                <Row wrap={false}>
                                                                    <b
                                                                        style={{
                                                                            marginRight: 5,
                                                                            opacity: 0.5,
                                                                        }}
                                                                    >
                                                                        {translate(
                                                                            'general.activeCampains',
                                                                        )}
                                                                        :
                                                                    </b>
                                                                    {context.bonificationOrder?.campanhaIds
                                                                        .reverse()
                                                                        .map(
                                                                            (
                                                                                c,
                                                                            ) => (
                                                                                <Tag
                                                                                    color={
                                                                                        colors[
                                                                                        context.campaigns?.findIndex(
                                                                                            (
                                                                                                d,
                                                                                            ) =>
                                                                                                d.id ===
                                                                                                c,
                                                                                        )
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        context.campaigns?.find(
                                                                                            (
                                                                                                d,
                                                                                            ) =>
                                                                                                d.id ===
                                                                                                c,
                                                                                        )
                                                                                            ?.nome
                                                                                    }
                                                                                </Tag>
                                                                            ),
                                                                        )}
                                                                </Row>
                                                            </AntTable.Summary.Cell>
                                                        </AntTable.Summary.Row>
                                                    </AntTable.Summary>
                                                )
                                                : undefined
                                        }
                                        footerObject={
                                            <OrderDetailsTableFooter
                                                order={context.bonificationOrder}
                                                moeda={getMoeda()}
                                            />
                                        }
                                    />
                                </TabPane>
                            )}
                            <TabPane
                                tab={
                                    <Row gutter={10}>
                                        <Col>{translate('general.actualSale')}</Col>

                                        <Semaphore
                                            order={context.order}
                                            moeda={getMoeda()}
                                        />
                                    </Row>
                                }
                                key={'default'}
                            >
                                <Table
                                    columns={itemsColumns}
                                    rowKey={(record: ItemResponse) =>
                                        `${record.id}-${context.order.key}`
                                    }
                                    summary={
                                        context.order &&
                                            context.order.campanhaIds.length > 0
                                            ? () => (
                                                <AntTable.Summary fixed={true}>
                                                    <AntTable.Summary.Row>
                                                        <AntTable.Summary.Cell
                                                            index={2}
                                                            colSpan={16}
                                                        >
                                                            <Row wrap={false}>
                                                                <b
                                                                    style={{
                                                                        marginRight: 5,
                                                                        opacity: 0.5,
                                                                    }}
                                                                >
                                                                    {translate(
                                                                        'general.activeCampains',
                                                                    )}
                                                                    :
                                                                </b>
                                                                {context.order.campanhaIds
                                                                    .reverse()
                                                                    .map((c) => (
                                                                        <Tag
                                                                            color={
                                                                                colors[
                                                                                context.campaigns?.findIndex(
                                                                                    (
                                                                                        d,
                                                                                    ) =>
                                                                                        d.id ===
                                                                                        c,
                                                                                )
                                                                                ]
                                                                            }
                                                                        >
                                                                            {
                                                                                context.campaigns?.find(
                                                                                    (
                                                                                        d,
                                                                                    ) =>
                                                                                        d.id ===
                                                                                        c,
                                                                                )
                                                                                    ?.nome
                                                                            }
                                                                        </Tag>
                                                                    ))}
                                                            </Row>
                                                        </AntTable.Summary.Cell>
                                                    </AntTable.Summary.Row>
                                                </AntTable.Summary>
                                            )
                                            : undefined
                                    }
                                    pointer
                                    dataSource={context.order?.tempItems ?? []}
                                    pagination={undefined}
                                    isLoading={context.loading}
                                    footerObject={
                                        <OrderDetailsTableFooter
                                            order={context.order}
                                            moeda={getMoeda()}
                                        />
                                    }
                                />
                            </TabPane>
                            {!!context.bonificationOrder &&
                                context.allBonificationOrders &&
                                context.allBonificationOrders.length > 0 && (
                                    <>
                                        {context.allBonificationOrders.map((or) => (
                                            <TabPane
                                                tab={
                                                    <Row gutter={10}>
                                                        <Col>
                                                            {translate(
                                                                'columns.order',
                                                            )}{' '}
                                                            - {or.key}{' '}
                                                        </Col>

                                                        <Semaphore
                                                            order={or}
                                                            moeda={getMoeda()}
                                                        />
                                                    </Row>
                                                }
                                                key={'order-key-' + or.key}
                                            >
                                                <Table
                                                    columns={itemsColumns}
                                                    rowKey={(
                                                        record: ItemResponse,
                                                    ) => `${record.id}-${or.key}`}
                                                    pointer
                                                    dataSource={or?.tempItems ?? []}
                                                    pagination={undefined}
                                                    isLoading={context.loading}
                                                    summary={
                                                        or.campanhaIds.length > 0
                                                            ? () => (
                                                                <AntTable.Summary
                                                                    fixed={true}
                                                                >
                                                                    <AntTable.Summary.Row>
                                                                        <AntTable.Summary.Cell
                                                                            index={
                                                                                2
                                                                            }
                                                                            colSpan={
                                                                                16
                                                                            }
                                                                        >
                                                                            <Row
                                                                                wrap={
                                                                                    false
                                                                                }
                                                                            >
                                                                                <b
                                                                                    style={{
                                                                                        marginRight: 5,
                                                                                        opacity: 0.5,
                                                                                    }}
                                                                                >
                                                                                    {translate(
                                                                                        'general.activeCampains',
                                                                                    )}

                                                                                    :
                                                                                </b>
                                                                                {or.campanhaIds
                                                                                    .reverse()
                                                                                    .map(
                                                                                        (
                                                                                            c,
                                                                                        ) => (
                                                                                            <Tag
                                                                                                color={
                                                                                                    colors[
                                                                                                    context.campaigns?.findIndex(
                                                                                                        (
                                                                                                            d,
                                                                                                        ) =>
                                                                                                            d.id ===
                                                                                                            c,
                                                                                                    )
                                                                                                    ]
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    context.campaigns?.find(
                                                                                                        (
                                                                                                            d,
                                                                                                        ) =>
                                                                                                            d.id ===
                                                                                                            c,
                                                                                                    )
                                                                                                        ?.nome
                                                                                                }
                                                                                            </Tag>
                                                                                        ),
                                                                                    )}
                                                                            </Row>
                                                                        </AntTable.Summary.Cell>
                                                                    </AntTable.Summary.Row>
                                                                </AntTable.Summary>
                                                            )
                                                            : undefined
                                                    }
                                                    footerObject={
                                                        <OrderDetailsTableFooter
                                                            order={or}
                                                            moeda={getMoeda()}
                                                        />
                                                    }
                                                />
                                            </TabPane>
                                        ))}
                                    </>
                                )}
                        </Tabs>
                    </Col>
                    <Col span={23} lg={{ span: 0 }}>
                        <Tabs
                            defaultActiveKey="bonification"
                            style={{ marginTop: 20 }}
                            key={context.bonificationOrder?.key}
                        >
                            {!!context.bonificationOrder && (
                                <TabPane
                                    tab={
                                        <Row gutter={10}>
                                            <Col>
                                                {translate('columns.bonification')}{' '}
                                                - {context.bonificationOrder.key}{' '}
                                            </Col>
                                            (
                                            <Semaphore
                                                order={context.bonificationOrder}
                                                moeda={getMoeda()}
                                            />
                                            )
                                        </Row>
                                    }
                                    key={'bonification'}
                                >
                                    <List
                                        columns={bonificationItemsColumns}
                                        rowKey={(record: ItemResponse) =>
                                            `${record.id}-${context.bonificationOrder.key}`
                                        }
                                        dataSource={
                                            context.bonificationOrder?.tempItems ??
                                            []
                                        }
                                        pagination={undefined}
                                        isLoading={context.loading}
                                        footerObject={
                                            <OrderDetailsTableFooter
                                                order={context.bonificationOrder}
                                                moeda={getMoeda()}
                                            />
                                        }
                                    />
                                </TabPane>
                            )}
                            <TabPane
                                tab={
                                    <Row gutter={10}>
                                        <Col>{translate('general.actualSale')}</Col>

                                        <Semaphore
                                            order={context.order}
                                            moeda={getMoeda()}
                                        />
                                    </Row>
                                }
                                key={'default'}
                            >
                                <List
                                    columns={itemsColumns}
                                    rowKey={(record: ItemResponse) =>
                                        `${record.id}-${context.order.key}`
                                    }
                                    dataSource={context.order?.tempItems ?? []}
                                    pagination={undefined}
                                    isLoading={context.loading}
                                    footerObject={
                                        <OrderDetailsTableFooter
                                            order={context.order}
                                            moeda={getMoeda()}
                                        />
                                    }
                                />
                            </TabPane>
                            {!!context.bonificationOrder &&
                                context.allBonificationOrders &&
                                context.allBonificationOrders.length > 0 && (
                                    <>
                                        {context.allBonificationOrders.map((or) => (
                                            <TabPane
                                                tab={
                                                    <Row gutter={10}>
                                                        <Col>
                                                            {translate(
                                                                'columns.order',
                                                            )}{' '}
                                                            - {or.key}{' '}
                                                        </Col>

                                                        <Semaphore
                                                            order={or}
                                                            moeda={getMoeda()}
                                                        />
                                                    </Row>
                                                }
                                                key={'order-key-' + or.key}
                                            >
                                                <List
                                                    columns={itemsColumns}
                                                    rowKey={(
                                                        record: ItemResponse,
                                                    ) => `${record.id}-${or.key}`}
                                                    dataSource={or?.tempItems ?? []}
                                                    pagination={undefined}
                                                    isLoading={context.loading}
                                                    footerObject={
                                                        <OrderDetailsTableFooter
                                                            order={or}
                                                            moeda={getMoeda()}
                                                        />
                                                    }
                                                />
                                            </TabPane>
                                        ))}
                                    </>
                                )}
                        </Tabs>
                    </Col>
                </Row>
            </ShadowContainer>
        </>
    );
};
